var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Data Gaji Tetap ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"employee_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIP","label-for":"employee_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"employee_id","reduce":function (employee_nip) { return employee_nip.id; },"placeholder":"Pilih Pegawai","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listPegawai,"label":"name"},model:{value:(_vm.dataParent.employee_id),callback:function ($$v) {_vm.$set(_vm.dataParent, "employee_id", $$v)},expression:"dataParent.employee_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tanggal Efektif","label-for":"effective_date"}},[_c('validation-provider',{attrs:{"name":"effective_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"effective_date","type":"date","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Efektif"},model:{value:(_vm.dataParent.effective_date),callback:function ($$v) {_vm.$set(_vm.dataParent, "effective_date", $$v)},expression:"dataParent.effective_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"component_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nama Komponen","label-for":"component_name","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"component_name","reduce":function (employee_nip) { return employee_nip.salary_component; },"placeholder":"Pilih Komponen","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listComponent,"label":"salary_component"},model:{value:(_vm.dataParent.component_name),callback:function ($$v) {_vm.$set(_vm.dataParent, "component_name", $$v)},expression:"dataParent.component_name"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Pengali","label-for":"multiplier"}},[_c('validation-provider',{attrs:{"name":"multiplier","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"multiplier","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Pengali"},model:{value:(_vm.dataParent.multiplier),callback:function ($$v) {_vm.$set(_vm.dataParent, "multiplier", $$v)},expression:"dataParent.multiplier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nominal","label-for":"nominal"}},[_c('validation-provider',{attrs:{"name":"nominal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nominal","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Nominal"},model:{value:(_vm.dataParent.nominal),callback:function ($$v) {_vm.$set(_vm.dataParent, "nominal", $$v)},expression:"dataParent.nominal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Deskripsi","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Deskripsi"},model:{value:(_vm.dataParent.description),callback:function ($$v) {_vm.$set(_vm.dataParent, "description", $$v)},expression:"dataParent.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.formSubmitted}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }